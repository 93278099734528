export const routes = [
    {
        path: '/businesses',
        name: 'businesses.browse',
        component: () => import(/* webpackChunkName: "BusinessesBrowse" */ '@/views/app/BusinessesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/businesses/:id/clone',
        name: 'businesses.clone',
        component: () => import(/* webpackChunkName: "BusinessesActions" */ '@/views/app/BusinessesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/businesses/create',
        name: 'businesses.create',
        component: () => import(/* webpackChunkName: "BusinessesActions" */ '@/views/app/BusinessesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/businesses/:id/edit',
        name: 'businesses.edit',
        component: () => import(/* webpackChunkName: "BusinessesActions" */ '@/views/app/BusinessesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/businesses/:id/delete',
        name: 'businesses.delete',
        component: () => import(/* webpackChunkName: "BusinessesActions" */ '@/views/app/BusinessesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/businesses/:id/show',
        name: 'businesses.show',
        component: () => import(/* webpackChunkName: "BusinessesActions" */ '@/views/app/BusinessesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]